
@import "~@/common/less/variable.less";

.apply-success {
  width: 100%;
  height: 100%;
  background: #fff;
  padding-top: 1rem;
  box-sizing: border-box;

  .icon {
    width: 2.2rem;
    height: 2.2rem;
    margin: 0 auto 0.64rem;

    .success-svg {
      width: 2.2rem !important;
      height: 2.2rem !important;
    }
  }

  .title {
    font-size: 0.32rem;
    color: #333333;
    text-align: center;
    margin-bottom: 0.2rem;
  }

  .tip {
    font-size: 0.28rem;
    color: #999999;
    text-align: center;
    margin-bottom: 0.6rem;
  }

  .btn {
    width: 7.02rem;
    height: 0.88rem;
    border-radius: 0.88rem;
    background: var(--main-color);
    font-size: 0.32rem;
    color: #fff;
    text-align: center;
    line-height: 0.88rem;
    margin: 0 auto;
  }
}

